@font-face {
  font-family: MonTserrat;
  src: url(../fonts/Montserrat-Regular.ttf);
}
::root {
  font-weight: 600 !important;
}
.tab-inner h4 {
  font-weight: 600;
}

.home-outer {
  font-family: MonTserrat;
  display: flex;
}

.sidenav-outer {
  width: 100%;
  /* margin: 0; */
  min-height: 100vh;
  background-color: "#fff";
}
.nav-link {
  position: relative !important;
}
.nav-link.active {
  background: #ffffff !important;
  box-shadow: -2px 1px 3px rgba(80, 80, 80, 0.25);
  background-color: none !important;
}
.nav-link.active::after {
  content: "";
  position: absolute;
  background: linear-gradient(135deg, #aa23ad 0%, #785bdf 100%);
  width: 5px;
  height: 100%;
  top: 0%;
  left: 0px;
}
.sidenavTabs {
  font-family: MonTserrat;
  flex-direction: column;
  margin-top: 5em !important;
  height: 276px !important;
}
.mainContent {
  background-color: #f2f4f6;
}
.category-outer {
  width: 100%;
  height: 500px;
  float: right;
}
.category {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 60%;
  left: 17%;
}
.categoryBtn {
  width: 175px;
  height: 40px;
  background: #ffffff;
  border-radius: 50px;
  border-image: var(--primaryColor) 10;
  border: 2px solid #aa23ad;
  background: var(--primaryColor);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: 14px;
  font-family: MonTserrat;
}
.primaryBtn {
  background: #ffffff;
  border-radius: 50px;
  border-color: linear-gradient(180deg, #aa23ad 0%, #785bdf 100%) !important;
  border: 2px solid #aa23ad;
  color: #aa23ad;
  font-weight: bold;
}

.primaryBlessingBtn {
  background: linear-gradient(180deg, #aa23ad 0%, #785bdf 100%) !important;;
  border-radius: 50px;
  /* border-color: linear-gradient(180deg, #aa23ad 0%, #785bdf 100%) !important; */
  border: 2px solid #aa23ad;
  color: #aa23ad;
  font-weight: bold;
}
.ageGroup {
  display: flex;
  padding-top: 7px;
  align-items: center;
}

.ageGroup label {
  font-size: 18px !important;
}

.catName {
  /* display: flex; */
  /* flex-direction: column; */
}
.uploadImg {
  display: flex;
  flex-direction: column;
}
.modelmain-outer {
  width: 570px;
}
.modal-dialog {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  margin: 0 !important;
}

.modelBody {
  width: 700px;
  max-width: 700px;
  padding: 1em !important;
}

.modal-content {
  border-radius: 20px !important;
  border: 2px solid var(--primaryColor) !important;
  width: auto !important;
}

/* /////   category /////  */

.catName > label,
.uploadImg > label,
.ageGroup > label,
.playlistModel > label {
  font-size: 16px;
  /* margin-bottom: 1em; */
  color: #525252;
  font-weight: bold;
  line-height: 24.38px;
}

.catName .objective-div{
  display: flex;
  gap: 16px;
}

.remove-btn{
  color: #fff;
  background-color: rgb(255, 0, 0,.7);
  border: none;
  border-radius: 6px;
  text-transform: uppercase;
  font-weight: 600;
  /* height: 60%; */
}

.catName .catInput,
.catName > textarea {
  width: 95%;
  /* margin-left: auto; */
  margin-right: auto;
  height: 36px;
  border-radius: 50px;
  border: 2px solid #9a9a9a;
  box-sizing: border-box;
  border-radius: 20px;
}

.catName > textarea{
  height: 100px !important;
}
.playlistModelTitle > h3 {
  color: var(--primaryColor);
  font-weight: 600;
  font-size: 35px;
  line-height: 43px;
}
.playlistModel > input {
  height: 36px;
  width: 60%;
  border-radius: 50px;
  border: 2px solid #9a9a9a;
  box-sizing: border-box;
  border-radius: 50px;
}
.playlistModel {
  justify-content: space-between;
}
.playlistModel > label {
  margin-top: auto;
  margin-bottom: auto;
  text-align: left;
}
.deletePlaylistTitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  /* Color 13 */

  color: #747474;
}
.catInput {
  margin-bottom: 1em;
  /* margin-top: 1em; */
  /* padding-left: 10px; */
  text-indent: 10px;
}
.catInput::placeholder {
  padding-left: 1em;
  font-size: 14px;
}

.inputFile {
  width: 176px;
  height: 124px;
  background: #dedede;
  border-radius: 10px;
  cursor: pointer;
  background-image: url(../images/uploadicon.png);
  background-repeat: no-repeat;
  background-position: center center;
  /* margin-left: auto;
  margin-right: auto; */
  /* padding-top: 2em !important; */
}
.inputFile::-webkit-file-upload-button {
  display: none;
}
input[type="file"] {
  color: transparent;
}
.upload-outer {
  position: relative;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  /* cursor: pointer; */
  padding: 10px;
}
.customInput {
  /* background-color: black; */
  position: absolute;
  z-index: 10;
  top: 0;
  height: 69px;
  cursor: pointer;
}
.customInput::-webkit-file-upload-button {
  display: none;
}
.uploadIcon {
  /* width: 10%; */
  position: absolute;
  top: 33%;
  left: 35%;
  cursor: pointer;
  /* text-align: center; */
  /* right: 43%; */
}
.ageGroup,
.ageGroup-inner {
  display: flex;
}
.ageGroup-inner {
  padding-left: 2em;
}
.ageGroup-inner > p {
  padding-right: 5px;
  /* padding-left: 1em; */
  margin-top: auto;
  margin-bottom: auto;
  color: #525252;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
}
.agegroupBtn {
  background-color: transparent;
  height: 30px;
  border: none;
  border-right: 2px solid #aa23ad;
  border-left: 2px solid #aa23ad;
  padding: 0px 10px;
  display: inherit;
  margin-left: -2px;
}

.agegroupBtn-outer {
  /* border-top: 3px solid #AA23AD; */
  border: 2px solid #aa23ad;
  height: 30px;
  margin-right: 2em;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  text-align: center;
  /* padding: 0px 10px; */
}
.agegroupBtn-outer > p {
  padding: 0 12px;
}
.modelFooter {
  display: flex;
  justify-content: start;
  /* margin-top: 3em; */
  margin-left: 2em;
}
.modelBtn {
  width: 175px;
  height: 37px;
  margin-right: 2em;
}
.fillBtn {
  background: var(--primaryColor) !important;
  color: #f2f4f6 !important;
  border-color: var(--primaryColor) !important;
  -webkit-background-clip: text;
  /* -webkit-text-fill-color: transparent */
}

.cancelBtn:hover {
  background: var(--primaryColor) !important;
  color: #f2f4f6;
}
.cancelBtn:hover > .saveBtn {
  background-color: #ffffff !important;
}
.saveBtn {
  background-color: #aa23ad !important;
  color: #f2f4f6 !important;
}

.navLink {
  background-color: #ffffff !important;
  color: #525252 !important;
  font-size: 24px !important;
  font-weight: 500;
  line-height: 1.2;
}
.tab-outer {
  padding-left: 1em;
}
.tab-span {
  width: 5px;
  height: 45px;
  background-color: var(--primaryColor);
  background-color: #aa23ad;
}
.navItem {
  display: flex;
  padding-left: 2em;
}

.outer-row {
  flex-direction: row;
  width: 100%;
}
.defaultTab {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 60%;
}
.defaultTab > p {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--primaryColor);
}

/* //// activity //  */

.activity-outer {
  display: flex;
  justify-content: space-evenly;
  /* margin-left: 4em; */
  padding-top: 5em;
}

.catItem,
.catItem2,
.actItem {
  z-index: 1;
  width: 200px;
  height: auto;
  background: #ffffff;
  /* margin-left: auto;
  margin-right: auto; */
  margin: 1em;
  box-shadow: 0px 1px 5px rgba(80, 80, 80, 0.25);
  border-radius: 10px;
  padding: 1em;
  position: relative;
  /* background-image: url(../images/Nutrition.png); */
  background-repeat: no-repeat;
  background-position: center center;
}


.catItem2
{
  z-index: 1;
  width: 250px;
  height: auto;
  background: #ffffff;
  /* margin-left: auto;
  margin-right: auto; */
  margin: 1em;
  box-shadow: 0px 1px 5px rgba(80, 80, 80, 0.25);
  border-radius: 10px;
  padding: 1em;
  position: relative;
  /* background-image: url(../images/Nutrition.png); */
  background-repeat: no-repeat;
  background-position: center center;
}

.actItem:hover,
.actitemImg:hover {
  cursor: pointer;
  background: #ec892b;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.catItem:hover {
  background: rgba(0, 0, 0, 0.85);
  box-shadow: 0px 1px 5px rgba(80, 80, 80, 0.25);
}

.catitemImg,
.actitemImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  border-radius: 5px;
  object-fit: cover;
}

.catitemImg:hover,
.catItem:hover > .catitemImg,
.catItem > p:hover,
.catItem:hover > .catitemName {
  opacity: 0.2;
}

.catitemName {
  background: var(--primaryColor);
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  margin-top: 1em;
  margin-bottom: 0;
  font-family: MonTserrat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.menu {
  background-image: url(../images/Menu.png);
  width: 13px;
  height: 13px;
  margin: 5px;
  position: absolute;
  top: 0;
  right: 0;
}

.catItem:hover > .itemOptions {
  opacity: 1;
  z-index: 2;
}

.itemOptions {
  z-index: 0;
  opacity: 0;
  position: absolute;
  width: 75%;
  top: 44%;
  left: 12%;
  justify-content: space-evenly;
  color: #ffffff;
  display: flex;
}
.itemOptionIcon {
  margin-bottom: 4px;
}
.itemOptions > img {
  margin: 5px;
  cursor: pointer;
}
.editOption {
  cursor: pointer;
  margin-left: 5px;
}
.displayNone {
  display: none;
}

/* //// playlist //// */

.playlist-outer {
  width: 70% !important;
  position: relative;
  top: 14%;
}
.playlistTitle > h3 {
  color: #525252;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.playlistTable {
  /* width: 700px; */
  height: auto;
  /* border: 1px solid #d4de7e; */
}
.tableIcon {
  width: 13%;
  margin-left: 10px;
  margin-bottom: 2px;
  cursor: pointer;
}
.icon-outer {
  display: flex;
  justify-content: flex-end;
}
.table-inner {
  background-color: #c9e165;
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
.tableTitle > p {
  color: #747474;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  text-align: left;
  /* margin-bottom: 5px !important; */
  margin-top: 10px;
  margin-left: 1em;
}
.darkLi {
  background-color: #f2f4f6;
}
.whiteLi {
  background-color: #ffffff;
}
.addPlaylistBtn {
  margin: 1em;
  float: right;
  width: auto;
  height: 30px;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  margin-right: -1em;
}

/* //// add playlist /////  */

table {
  font-family: MonTserrat;
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #c9e165;
}
.table-title {
  background-color: #c9e165;
  width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
th {
  color: #747474;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: left;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
.tableInputTxt {
  background: transparent;
  border: none;
  padding-left: 1em;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #747474;
  align-items: center;
}
.tableInputTxt:focus {
  outline: none;
}

.ageCountInput {
  border: none;
  width: 30px;
  text-align: center;
  outline: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button:hover {
  background: var(--primaryColor) !important;
  color: #f2f4f6 !important;
  border-color: var(--primaryColor) !important;
  -webkit-background-clip: text;
}

.primaryBlessingsBtn {
  background: var(--primaryColor) !important;
  border-radius: 50px;
  /* border-color: linear-gradient(180deg, #aa23ad 0%, #785bdf 100%) !important; */
  border-color: var(--primaryColor) !important;
  border: 2px solid #aa23ad;
  color: #f2f4f6 !important;
  font-weight: bold;
}