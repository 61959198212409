.description {
  font-family: "Open Sans", sans-serif;
  /* font-family: 'Open Sans', sans-serif; */
  padding-bottom: 20px !important;
}
.logo {
  height: 70px;
}
.details {
  margin: 0px;
  font-family: "Open Sans", sans-serif;
}
.goToHeading {
  font-weight: 800;
  font-family: "Open Sans", sans-serif;
}

/* //////////mobile media querry */
/* On screens that are 600px or less, set the background color to olive */
@media screen and (max-width: 600px) {
  .goToHeading {
    margin-top: 8vh;
  }

  .lastColumn {
    margin: 8vh 0px;
  }
  .copyRight {
    text-align: center;
    margin: 10px 0px;
  }
}
