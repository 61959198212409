.ageCountInput{
    border: none;
    width: 30px;
    text-align: center;
    outline: none;
    
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}