.navbarSearch:placeholder-shown,
.navbarSearch:focus,
.navbarSearch {
  padding-left: 1em !important;
  color: #ccbdff;
  border: none;
  outline: none;
  margin-top: -5px;
}
.navbarSearch::placeholder {
  color: #ccbdff;
}
.searchInputOuter {
  display: flex;
  position: relative;
}
.searchInputInner {
  position: absolute;
  height: 350px;
  width: 100%;
  overflow-y: scroll;
  background-color: white;
  border-radius: 5px;
  z-index: 10;
  top: 50px;
  box-shadow: 0px 2px 11px 1px #6610f2;
}
