.input {
  border-radius: 20px;
  border-color: transparent;
  padding: 5px;
  background-color: #ffffff;
  width: 372px;
  height: 40.56px;
}
/* .adminHome{
    background-color: #F2F4F6;
    height: 100vh;
} */
.mainButton {
  background: #ffffff;
  border-radius: 50px;
  border-color: linear-gradient(180deg, #aa23ad 0%, #785bdf 100%) !important;
  border: 2px solid #aa23ad;
  color: #aa23ad;
  font-weight: bold;
}

.primaryText {
  background: var(--primaryColor);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
}

/* css done by rohit */

.text-center {
  text-align: center !important;
}

.table {
  border: 1px solid #c9e165;
}

.table .thead-light th {
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.myaccount-table .table th,
.myaccount-table table th {
  padding: 20px 10px;
  font-size: 19px;
  color: #333;
  font-weight: 600;
}

.myaccount-table .table td,
.myaccount-table table td {
  padding: 15px 10px;
  vertical-align: middle;
  background-color: #fff;
}

.modal-dialog {
  width: 700px !important;
  max-width: 700px !important;
}

.order-det-modal .modal-content{
  width: 100% !important;
}
.order_modal_details,
.order_modal_total {
  border: 1px solid #e7eeea;
  box-shadow: 0 0 11.83px 1.17px rgb(0 0 0 / 6%);
}

.order_modal_total {
  border-radius: 5px;
  overflow: hidden;
}

.order_modal_total__subtotal {
  align-items: center;
  border: 1px solid var(--theme-bg-2-light);
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.5rem;
}

.export-order-btn {
  border: none;
  float: right;
  width: fit-content !important;
  max-width: fit-content !important;
  padding: 10px 35px;
  border: solid 2px #a32bb4;
  border-radius: 100px;
  margin-bottom: 10px;
}

.table select {
  border: 1px solid #eee;
  padding: 4px 6px;
}

.color-input {
  border-radius: 3px;
  border: 1px solid #333;
  width: 100%;
  max-width: 200px;
  padding: 4px;
}

.categroy-delete {
  position: absolute;
  top: 13px;
  right: 55px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  color: #fff;
  background-color: #777;
  padding: 5px;
  border-radius: 50%;
}

.categroy-update{
  position: absolute;
  top: 13px;
  right: 12px;
  height: 36px;
  width: 36px;
  cursor: pointer;
  color: #fff;
  background-color: #785bdf;
  padding: 5px;
  border-radius: 50%;
}

.product-delete {
  top: 13px;
  margin: 0 4px;
  right: 55px;
  height: 30px;
  width: 30px;
  cursor: pointer;
  color: #fff;
  background-color: #777;
  padding: 5px;
  border-radius: 50%;
}

.product-update{
  top: 13px;
  right: 12px;
  height: 30px;
  width: 30px;
  cursor: pointer;
  color: #fff;
  background-color: #785bdf;
  padding: 5px;
  border-radius: 50%;
}

.product-add{
  background-color: rgba(128, 0, 0,.79);
}


.order-view-btn {
  background-color: #aa23ad !important;
  color: #fff !important;
  font-weight: 500 !important;
}

.button2 {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 20;
  border: solid 2px #a32bb4;
  background: linear-gradient(90deg, #a32bb4 -4.7%, #854cd2 75.33%);
  -webkit-background-clip: text;
  background-clip: text;
  border-radius: 100px;
  color: #a32bb4;
  margin-bottom: 10px;
}

.button2:hover {
  color: #fff !important;
}


.text-red{
  color: red;
  font-weight: 700;
}

.category-file{
  color: #000 !important;
}

.order_modal_details.flex-column.flex-md-row {
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 10px;
}

.product_details-content {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-left: 1rem;
  gap: 12px;
}

.product_details-content h1,
.product_details-content p{
  margin-bottom: 0;
}

.order-det-address{
  max-width: 225px;
}

.order-det-status{
  text-transform: capitalize;
}