.container {
    margin: 30px;
    box-sizing: border-box;
}

.inputs {
    width: 90%;
    margin-right: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
}

.newinput {
    width: 90%;
    margin-right: 10px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.textarea {
    width: 90%;
    margin-top: 20px;

}
.variantHeader{
    padding: 5px 5px 5px 0px;
    margin-left: 25px;
    width: 100%;
    font-weight: 600;
    font-family: 'Montserrat';
    font-size: 25px;
    color: gray;
}

.age{
    display: flex;
    justify-content: flex-start;
    height: 32px;
}
.agep{
    display: flex;
    justify-content: center;
    align-items: center;
    width:25px;
    margin: 10px
}
.agebutton{
    border: 2px solid #cccaca;
    border-radius: 10px;
    height:30px;
    width:30px;
}

.img{
    margin-top: 10px;
    width: 90%;
    border-radius: 5px;
    height: 172px;
    border: 1.3px solid #cccaca;
}

.filess {
    width: 90%;
    margin-top: 20px;
    border: 2px dotted rgb(98, 67, 211) !important;
}

.inputs2 {
    width: 70%;
    margin-bottom: 20px;
    margin-top: 20px;

}

.inputs3 {
    width: 90%;
    margin-right: 10px;
    margin-bottom: 20px;
    margin-top: 20px !important;
}

.button {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20;
    background: linear-gradient(90deg, #A32BB4 -4.7%, #854CD2 75.33%);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
    border-radius: 50px;
    color: white
}

.button2 {
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 20;
    border: solid 2px #A32BB4;
    /* border-image-source:linear-gradient(90deg, #A32BB4 -4.7%, #854CD2 75.33%); */
    background: linear-gradient(90deg, #A32BB4 -4.7%, #854CD2 75.33%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    border-radius: 100px;
    color: white;
    margin-bottom: 10px;
    /* pointer-events: none; */
    /* border-image-slice: 1; */

}
.backbutton {
    margin-left:40px;
    margin-top: 10px;
    border: solid 2px #A32BB4;
    /* border-image-source:linear-gradient(90deg, #A32BB4 -4.7%, #854CD2 75.33%); */
    background: linear-gradient(90deg, #A32BB4 -4.7%, #854CD2 75.33%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    border-radius: 20px;
    color: white;
}

.headertext {
    padding-left: 40px;
    padding-right: 40px;
    /* padding-top: 2px;
        padding-bottom: 2px; */
    margin-top: 20;
    /* border-image-source:linear-gradient(90deg, #A32BB4 -4.7%, #854CD2 75.33%); */
    background: linear-gradient(90deg, #A32BB4 -4.7%, #854CD2 75.33%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    color: white;
    /* pointer-events: none; */
    /* border-image-slice: 1; */


}

.button3 {

    margin-top: 20px;
    background: #D9D9D9;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    border-radius: 50px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    color: black
}

.cattext {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;
    margin-left: 15px;
    margin-top: 15px;
    text-wrap: wrap;
}

.subcattext {
    color: #979797;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    margin-left: 8px;
}

.catdot {
    background: linear-gradient(90deg, #A32BB4 -4.7%, #854CD2 75.33%);
    width: 10px;
    height: 10px;
    border-radius: 5px;
}

.catdiv {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    margin-left: 15px;
    margin-top: 10px;
}

.viewbutton {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: 10px;
    margin-top: 20;
    border: solid 2px #A32BB4;
    /* border-image-source:linear-gradient(90deg, #A32BB4 -4.7%, #854CD2 75.33%); */
    background: linear-gradient(90deg, #A32BB4 -4.7%, #854CD2 75.33%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    border-radius: 100px;
    color: white;
    margin-bottom: 10px;


}

.viewbutton:hover {
    color: white;
    background-color: rgb(167, 19, 19);
}

.newcatbutton {

    margin-top: 20px;
    margin-bottom: 20px;
    background: #D9D9D9;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    border-radius: 50px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
    color: black
}

.newcattext {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 14px;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
}

.modalbutton {

    width: 100%;
    margin-top: 20px;
    background: linear-gradient(90deg, #A32BB4 -4.7%, #854CD2 75.33%);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
    border-radius: 50px;
    color: white
}

.colormodalbutton {

    background: linear-gradient(90deg, #A32BB4 -4.7%, #854CD2 75.33%);
    /* box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3); */
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 50px;
    width: 100%;
    margin-top: 20px;
    color: white
}