@font-face {
  font-family: MontserratRegular !important;
  src: url("./Assets/fonts/Montserrat-Regular.ttf") !important;
  font-weight: normal;
  font-style: normal;
}
:root {
  --primaryColor: linear-gradient(180deg, #aa23ad 0%, #785bdf 100%);
  font-family: MontserratRegular !important;
}
.App {
  text-align: center;
  font-family: MontserratRegular;
  color: #525252;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.innerPageContent {
  background-color: #f2f4f6;
  min-height: 120vh;
  width: 100%;
}
.bottomLine {
  width: 100%;
  height: 5px;
  position: fixed;
  bottom: 0;

  /* Gradient 1 */

  background: linear-gradient(180deg, #aa23ad 0%, #785bdf 100%);
}

.primaryText {
  background: var(--primaryColor);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.d-none {
  display: none;
}
.sidebarOuter {
  display: block;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

td {
  width: 20% !important;
}

.searchResultEl:hover {
  background: #9e5ba4;
  color: white;
}

.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 100;
}

/* custom loader */
.loader-element {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: none;
}
.custom-loader {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  padding: 6px;
  background: conic-gradient(from 135deg at top, #766df4 90deg, #0000 0) 0
      calc(50% - 4px) / 17px 8.5px,
    radial-gradient(
        farthest-side at bottom left,
        #0000 calc(100% - 6px),
        #766df4 calc(100% - 5px) 99%,
        #0000
      )
      top right/50% 50% content-box content-box,
    radial-gradient(
        farthest-side at top,
        #0000 calc(100% - 6px),
        #766df4 calc(100% - 5px) 99%,
        #0000
      )
      bottom / 100% 50% content-box content-box;
  background-repeat: no-repeat;
  animation: s1 1s infinite linear;
}

@keyframes s1 {
  100% {
    transform: rotate(1turn);
  }
}


.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 10;
}


.age-flex{
  display: flex;
  gap: 10px;
  /* justify-content: space-between; */
  margin-bottom: 8px; 
}

.custom-loader {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background: 
    radial-gradient(farthest-side,#766DF4 94%,#0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%,#766DF4);
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
  animation:s3 1s infinite linear;
  align-self: center;
  position: absolute;
  top: 42%;
  left: 50%;
}

@keyframes s3{ 
  100%{transform: rotate(1turn)}
}

.default-text{
  margin: 0;
  margin-top: 1rem;
}

.def-badge{
  border: 1px solid #ccc;
  border-radius: 5px;
  width: fit-content;
  padding: 2px 5px;
  text-transform: capitalize;
  box-shadow: 2px 2px #ccc;
}