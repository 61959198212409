.activityInnner {
    background-color: #f2f4f6;
  }
  .screenTitle {
    font-weight: 600;
    font-size: 35px;
    line-height: 43px;
    /* identical to box height */
    /* Color 4 */
    color: #aa23ad;
  }
  .tableImgOuter {
    /* background-color: #ffffff; */
    width: 60px;
    height: 60px;
    /* box-shadow: 0px 1px 5px rgba(80, 80, 80, 0.25); */
    border-radius: 10px;
  }
  .tableImg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    padding-top: 5px;
    border-radius: 10px;
  }
  