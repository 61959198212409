.catBackground {
  background-image: url(../../../../Assets/images/Background.png);
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 20px;
  padding-left: 0 !important;
  margin: 1em 0 1em 0;
}
.catTitleImgBackground {
  width: 125px;
  height: 91px;

  background: #ffffff;

  box-shadow: 0px 1px 5px rgba(80, 80, 80, 0.25);
  border-radius: 20px;
}
.catTitle {
  font-weight: 600;
  font-size: 35px;
  line-height: 43px;
  color: #ffffff;
}
.catName {
  margin-left: -0.8em;
}
.attributeRadio {
  width: 20px;
  height: 20px;
  background: #dedede;
  /* Color 11 */

  border: 1px solid #bfbfbf;
  box-sizing: border-box;
}
.radioDiv > p {
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  /* Color 13 */
  padding: 0 7px 0px 7px;

  color: #747474;
}
.formLabel{
  font-size: 20px;
  /* margin-bottom: 1em; */
  color: #525252;
  font-weight: bold;
  line-height: 24.38px;
}