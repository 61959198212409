@font-face {
  font-family: cookie;
  src: url(../fonts/Cookie-Regular.ttf);
}

.content {
  height: 100vh;
  width: 100%;
  margin: 0px !important;
  padding: 0px !important;
  background: linear-gradient(180deg, #aa23ad 0%, #785bdf 100%);
}
.loginHomeBtn {
  width: 374px;
  height: 50px;
  background: #c9e165;
  border-radius: 100px;
  border-color: transparent;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #525252;

}
.bodyContent {
  height: 100vh;
  max-width: 99.5vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.bodyContent > h1 {
  font-family: cookie;
  padding-top: 1em;
  color: #ffffff;
  font-weight: normal;
  /* font-size: 28px;  */
  /* line-height: 28px; */
  text-align: center;
}
.bodyContent > button {
  margin: 1em 0 2em 0em;
}
.modal-dialog {
  left: 0 !important;
}

.loginButton{
  height: 65px;
  background-color: #f98da0;
  border: none;
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
  border-radius: 20px 20px 0px 0px;
}
.loginButton2{
  height: 55px;
  background-color: #1f1cda;
  border: none;
  color: #ffffff;
  /* font-weight: bold; */
  /* font-size: 20px; */
  /* border-radius: 20px 20px 0px 0px; */
}
.loginHeader{
  height: 55px;
  background-color: lightgray;
  border: none;
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
  border-radius: 20px 20px 0px 0px;
}
.otpText{
  text-transform: uppercase;
  font-size: 17px;
  font-weight: bold;
  color: gray;
  margin: 1rem;
}
.enterOtpInput{
  padding: 8px 12px;
  border: 1px solid lightgray;
  border-radius: 6px;
  margin-bottom: 0.5rem;
}

.loginBtn{
  margin-bottom: 1rem;
}