.container {
    margin: 30px;
    height: 150vh;
    
}
.addcontainer{
    display: flex;
    margin: 10px;
    height: 150vh;
}
.box{
    display: inline-block;
    text-align: center;
    margin: auto;
    margin-top: 20px;
}
.table{
    max-height:150vh;
    overflow: scroll;
}
.inputs {
    padding:20px;
    width: 90%;  
}
.editInputs{
    margin-top: 10px;
    width: 80%;
}

.addUpload{
    width: 350px;
    margin: 0px 20px 0px 20px;
    border-radius: 5px;
    height: 222px;
    border: 1.3px solid #cccaca;
}
.editupload{
    width: 80%;
    border-radius: 5px;
    height: 205px;
    border: 1.3px solid #cccaca;
}


.imgdiv{
    margin-top: 20px;
}

.upload {
    width: 90%;
    border-radius: 5px;
    height: 170px;
    border: 1.3px solid #cccaca;
}

.description {
    width: 90%
}

.button {
    padding: 10px ;
    margin: 20px;
    width: 8rem;
    background: linear-gradient(90deg, #A32BB4 -4.7%, #854CD2 75.33%);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
    border-radius: 50px;
    color: white
}

.headertext {
    margin-top: 10px;
    font-size: 30px;
    font-weight: 500;
    background: linear-gradient(90deg, #A32BB4 -4.7%, #854CD2 75.33%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    color: white;
}

.icons{
    margin:0px 15px;
    font-size: 25px;
}
.icons :hover{
    cursor: pointer;
}

.backbutton{
    font-size: 40px;
    margin: 0;
}

.wrapper{
    position: fixed;
    left:0;
    right:0;
    bottom:0;
    top:0;
    background-color: rgba(189,189,189,0.5);
}

.delmodal{

    border-radius: 10px;
    
    border: 2px solid rgb(189,189,189);
    background-color: #fff;
    max-width: 30rem;
    position: fixed;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    padding:1rem;
    text-align: center;
}

.delMOdbutton{
    padding: 3px 20px;
    margin: 10px 20px;
    background: linear-gradient(90deg, #A32BB4 -4.7%, #854CD2 75.33%);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
    border-radius: 50px;
    color: white

}
.logo{
   width: 30%;
}

.delpopup{
     padding:10px;
}

.editmodal{
    border-radius: 10px;
    overflow-y: scroll;
    border: 2px solid rgb(189,189,189);
    background-color: #fff;
    position: fixed;
    top:10%;
    left:25%;
    bottom:12% ;
    right:25%;
    margin:auto;
    padding-left: 1rem;
}

